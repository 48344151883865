import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {AbsoluteImageComponent} from '../../../shared/components/absolute-image/absolute-image.component';
import {AbsoluteCircleComponent} from '../../../shared/components/absolute-circle/absolute-circle.component';
import {NgIf, NgClass, NgTemplateOutlet} from '@angular/common';

@Component({
    selector: 'auth-screen',
    templateUrl: './auth-screen.component.html',
    styleUrls: ['./auth-screen.component.css'],
    standalone: true,
    host: {ngSkipHydration: 'true'},
    imports: [NgIf, NgClass, NgTemplateOutlet, AbsoluteCircleComponent, AbsoluteImageComponent]
})
export class AuthScreenComponent {
    @ContentChild('screenContent') screenContentRef?: TemplateRef<any> = null;
    @Input() title: string = "";
    @Input() showAbsoluteStaff: boolean = true;
    readonly color = 'linear-gradient(270deg, #28B3F7 0.65%, #506AFF 99.35%)'
    readonly socialSpikeSrc = "/img/welcome/spike-login.svg"
}
